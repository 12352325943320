<template>
<div>
	<CRow>
		<CCol sm="12" md="8">
        <CCard>
          <CCardHeader>
            <h4>New Card Fee</h4>      
          </CCardHeader>
          <CCardBody> 
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>
            <CForm>
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Factor</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-input v-model="factorName" placeholder="Enter factor name"></b-form-input>
                   </b-col>                  
              </b-row><br/>
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small">Value</label>
                  </b-col>   
                  <b-col sm="9">                      
                    <b-input-group>
                        <b-form-input v-model="value" placeholder="Enter factor value" />
                        <b-input-group-append>                        
                        <b-button variant="info" v-on:click="addToList()" >Add</b-button>
                        </b-input-group-append>
                    </b-input-group>
                   </b-col>                  
              </b-row><br/>            
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >List Values</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-input-group>
                        <b-form-select v-model="values" :options="optValues"></b-form-select>
                        <b-input-group-append>                        
                        <b-button variant="danger" v-on:click="remFromList()">Remove</b-button>
                        </b-input-group-append>
                    </b-input-group>
                   </b-col>                  
              </b-row><br/>
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" ></label>
                  </b-col>   
                  <b-col sm="9"> 
                    <ul>
                        <div v-for="(value, key, index) in optValues"> 
                            <li>{{ value }}</li>
                        </div>
                    </ul>
                  </b-col>                
              </b-row><br/>     
              <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9"> 
                       <b-button-group>
                         <router-link to="/factor" tag="b-button">Cancel</router-link>
                         <b-button  v-on:click="CreateFactor()" variant="primary">Save</b-button>
                       </b-button-group>    
                  </b-col>                  
              </b-row><br/>
            </CForm>         
           </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
 import TrService from '@/api/TransactService.js';

  export default {    
    data() {
      return {  
          factorName:'', 
          value:'',        
          values:'',         
          optValues:[],
          seen:false,
          msg :'',
          color:''          
        };
    },
    created () {   
       //this.getConfigList();  
       //this.getUserGroupList(); 
    },
    methods: {

      addToList(){          
          var val = this.value; this.values = val;
          if(!this.optValues.includes(val)){
              this.optValues.push(val); 
          }          
      },

      remFromList(){
          var val = this.values;
          var key = this.optValues.findIndex(v => v == val);
          this.optValues.splice(key,1);          
      },

      CreateFactor(){ 
         
        let params = {}; var validate = [];
        params['name']   = this.factorName;
        params['values'] = this.optValues;        

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }

        if(!validate.includes(false) && this.optValues.length > 0 ){   //console.log(params); 
            if(localStorage.getItem('sa')==="true"){  
              this.CreUpdSingleFactor(params); 
            } else { 
              this.msg   = 'Sorry, your role not allowed this action!'; 
              this.color = 'warning'; this.seen  = true;
            }
            
        } else {
           this.msg   = 'Data Not Complete!'; 
           this.color = 'warning'; this.seen  = true;          
        } 

        setTimeout( () =>  this.seen=false , 5000);

      },   

      CreUpdSingleFactor: function(params) { 
          TrService.CreUpdSingleFactor(params).then(resp => {   //console.log(resp);           

            if(resp.message=="Success"){
                this.msg   = 'Create/Update Factor Successfully'; 
                this.color = 'success';  this.seen  = true;

              } else {
                this.msg   = !resp.data.message ? "Create/Update Factor Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);  
              }
            
           }, error => {
            console.log('error');    
          });
      },        
      
    }
  }
</script>